import { format, setGlobalDateI18n } from "fecha";

/* eslint-disable */
setGlobalDateI18n({
  dayNamesShort: ["zo", "ma", "di", "wo", "do", "vr", "za"],
  dayNames: [
    "zondag",
    "maandag",
    "dinsdag",
    "woensdag",
    "donderdag",
    "vrijdag",
    "zaterdag",
  ],
  monthNamesShort: [
    "jan",
    "feb",
    "mar",
    "apr",
    "mei",
    "jun",
    "jul",
    "aug",
    "sep",
    "okt",
    "nov",
    "dec",
  ],
  monthNames: [
    "januari",
    "februari",
    "maart",
    "april",
    "mei",
    "juni",
    "juli",
    "augustus",
    "september",
    "oktober",
    "november",
    "december",
  ],
  amPm: ["am", "pm"],
  // D is the day of the month, function returns something like...  3rd or 11th
  DoFn(D) {
    return (
      D +
      ["th", "st", "nd", "rd"][
        D % 10 > 3 ? 0 : ((D - (D % 10) !== 10) * D) % 10
      ]
    );
  },
});
/* eslint-enable */

export function getMostRecentTimestamp(x, y) {
  const date1 = new Date(x);
  const date2 = new Date(y);
  // returns timestamp
  return date1 > date2 ? x : y;
}

export function formatDateTime(date) {
  const inputDate = new Date(date);
  if (Number.isNaN(new Date(date).getDate())) {
    throw new Error(`Invalid date object passed to formatDateTime: ${date}`);
  }

  let formatted = "";
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(today.getFullYear() - 1);

  if (inputDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
    formatted = `vandaag, ${format(date, "shortTime")}`;
  } else if (
    inputDate.setHours(0, 0, 0, 0) === yesterday.setHours(0, 0, 0, 0)
  ) {
    formatted = `gisteren ${format(date, "shortTime")}`;
  } else if (inputDate > oneYearAgo) {
    formatted = format(date, "D MMMM, HH:mm");
  } else {
    formatted = format(date, "D-MM-YYYY");
  }

  return formatted;
}

export function formatConversationDate(date) {
  if (Number.isNaN(new Date(date).getDate())) {
    throw new Error(
      `Invalid date object passed to formatConversationDate: ${date}`
    );
  }

  let convoDate;
  const today = format(new Date(), "isoDate");
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const conversationDate = format(date, "isoDate");
  if (conversationDate === today) {
    convoDate = format(date, "shortTime");
  } else if (conversationDate === format(yesterday, "isoDate")) {
    convoDate = `gisteren, ${format(date, "shortTime")}`;
  } else {
    convoDate = format(date, "D MMMM YYYY, HH:mm");
  }

  return convoDate;
}

export function formatMessageDateTime(date) {
  if (Number.isNaN(new Date(date).getDate())) {
    throw new Error(
      `Invalid date object passed to formatMessageDateTime: ${date}`
    );
  }

  const inputDate = new Date(date);
  let formatted = "";

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(today.getFullYear() - 1);

  if (inputDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
    formatted = `${format(date, "HH:mm")}`;
  } else if (
    inputDate.setHours(0, 0, 0, 0) === yesterday.setHours(0, 0, 0, 0)
  ) {
    formatted = `${format(date, "D MMMM, HH:mm")}`;
  } else if (inputDate > oneYearAgo) {
    formatted = format(date, "D MMMM, HH:mm");
  } else {
    formatted = format(date, "D-MM-YYYY");
  }

  return formatted;
}

export function getLastModulesActivity(nestedArray) {
  const mostRecentActivity = nestedArray
    .flatMap((module) => module.steps)
    .filter((step) => step.timestamp) // filter out steps that don't have a timestamp
    .filter((step) => step.shared_with_coach) // filter out steps that are not shared with coach
    .map((step) => step.timestamp)
    .sort((x, y) => y - x)[0];
  return mostRecentActivity;
}

export function getLastExerciseActivity(array) {
  const mostRecentActivity = array
    .filter((exercise) => exercise.timestamp) // filter out exercises that don't have a timestamp
    .filter((exercise) => exercise.shared_with_coach) // filter out exercises that are not shared with coach
    .map((exercise) => exercise.timestamp)
    .sort((x, y) => y - x)[0];
  return mostRecentActivity;
}
