/* eslint-disable react/prop-types */

import "./App.css";
import "./index.css";
import React, { useRef, useState, useEffect } from "react";
// import firebase from "firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { BsThreeDots } from "react-icons/bs";
import { RiCloseCircleLine } from "react-icons/ri";
import { GrClose } from "react-icons/gr";
import UserInfo from "./UserInfo";
import WelcomeMessage from "./components/WelcomeMessage";
import DeleteMessage from "./components/DeleteMessage";
import SignIn from "./components/SignIn";
import {
  formatMessageDateTime,
  formatConversationDate,
} from "./functions/datetime";

// placeholder photo
import profilePlaceholder from "./assets/profile-placeholder.svg";

import firebase from "./firebase";

const auth = firebase.auth();
const firestore = firebase.firestore();

function Conversation({
  conversation,
  callback,
  activeChatId,
  setActiveChatId,
  selectedConversationRef,
}) {
  const isUnread = () =>
    conversation.last_user_message_timestamp > conversation.last_read_by_coach;

  // if coach clicks chat, new lastread is set in firebase.
  const updateLastRead = (value) => {
    const datetime = Date.now();
    const getChatCollection = firestore.collection("chat").doc(value.user_id);
    getChatCollection.update({
      last_read_by_coach: datetime,
    });
  };

  return (
    <div
      ref={
        activeChatId === conversation.user_id ? selectedConversationRef : null
      }
    >
      <button
        className={`relative ${activeChatId === conversation.user_id ? "border-blue-dark" : null
          } w-full hover:bg-gray-100 px-3 py-2 my-2 rounded-lg cursor-pointer flex items-center text-sm border-2 transition duration-150 ease-in-out bg-grey`}
        onClick={() => {
          callback(conversation);
          updateLastRead(conversation);
          setActiveChatId(conversation.user_id);
        }}
      >
        <p className="absolute top-0 right-2 block ml-2 mt-2 font-display font-regular text-xs text-purple">
          {conversation?.last_message_timestamp
            ? formatConversationDate(conversation.last_message_timestamp)
            : ""}
        </p>
        <div className="flex w-full p-1">
          <div className="flex items-center ">
            {isUnread() && (
              <div className="h-4 w-4 bg-blue rounded-full mr-4" />
            )}
          </div>
          <div className="flex flex-col justify-start items-start relative">
            <span className="block font-display font-semibold text-base text-purple">
              {conversation.user_name}
            </span>
            <span className="block font-display font-regular text-xs text-purple">
              {conversation.user_organisation}
            </span>
          </div>
        </div>
      </button>
    </div>
  );
}

function Conversations({
  user,
  callback,
  conversationSelectedCallback,
  activeChatId,
  setActiveChatId,
}) {
  const query = firestore
    .collection("chat")
    .where("coach", "==", user.uid)
    .orderBy("last_message_timestamp", "desc");
  const [conversations] = useCollectionData(query);
  const inputRef = useRef();
  const selectedConversationRef = useRef();
  const [searchParam, setSearchParam] = useState("");

  function searchUser() {
    setSearchParam(inputRef.current.value);
  }

  function clearSearch() {
    document.getElementById("searchBar").value = "";
    setSearchParam("");
  }

  useEffect(() => {
    if (selectedConversationRef.current) {
      selectedConversationRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  });

  return (
    <div className="max-h-screen flex flex-col">
      <div className="flex w-full items-center space-x-3 pl-3 py-3">
        <a href="/">
          <img
            className="h-9 w-9 object-cover"
            src={`${process.env.PUBLIC_URL}/five_steps.png`}
            alt="logo"
          />
        </a>
        <button onClick={callback} type="button">
          <img
            className="h-9 w-9 rounded-full object-cover"
            src={user.photoURL || profilePlaceholder}
            alt="profielafbeelding"
          />
        </button>
        <span className="block ml-2 font-display font-bold text-black">
          {user.displayName}
        </span>
      </div>
      <div className="relative flex items-center text-gray-600 focus-within:text-gray my-3 mx-3">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className="w-6 h-6 text-purple"
          >
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </span>
        <input
          aria-placeholder="Zoeken"
          placeholder="Zoeken"
          className="py-2 pl-10 block w-full font-display rounded-lg bg-grey outline-none focus:text-gray-700"
          type="search"
          name="search"
          required
          id="searchBar"
          ref={inputRef}
          onKeyUp={searchUser}
        />
        <GrClose
          className="absolute right-2 flex cursor-pointer"
          onClick={() => clearSearch()}
        />
      </div>
      <div
        id="conversationsContainer"
        className="m-2 flex flex-nowrap flex-col px-1 flex-grow overflow-y-auto"
      >
        {conversations &&
          conversations
            .filter((item) => {
              if (!item.user_name || item.user_organisation === undefined) {
                return false;
              }
              return (
                item.user_name
                  .toLowerCase()
                  .includes(searchParam.toLowerCase()) ||
                item.user_organisation
                  .toLowerCase()
                  .includes(searchParam.toLowerCase())
              );
            })
            .map((item) => (
              <Conversation
                conversation={item}
                callback={(conversation) =>
                  conversationSelectedCallback(conversation)
                }
                conversations={conversations}
                activeChatId={activeChatId}
                setActiveChatId={setActiveChatId}
                selectedConversationRef={selectedConversationRef}
              />
            ))}
      </div>
    </div>
  );
}

function CoachProfile({ user, callback }) {
  const [isSaveButtonVisible, setButtonVisibility] = useState(false);
  let [currentDescription, setDescription] = useState();
  let [currentUsername, setUsername] = useState();

  firebase
    .firestore()
    .collection("users")
    .doc(user.uid)
    .get()
    .then((docRef) => {
      setDescription(docRef.data().user_description);
    });

  firebase
    .firestore()
    .collection("users")
    .doc(user.uid)
    .get()
    .then((docRef) => {
      setUsername(docRef.data().naam);
    });

  const logOut = () => {
    firebase.auth().signOut();
  };

  function showSave() {
    setButtonVisibility(true);
  }

  async function saveDescription() {
    const getDescription = firestore.collection("users").doc(user.uid);
    await getDescription.update({
      user_description: currentDescription,
    });

    setButtonVisibility(false);
  }

  return (
    <div className="relative my-5 mx-5">
      <button onClick={callback} className="absolute top-0 -right-10 z-50">
        <RiCloseCircleLine className="mx-9" />
      </button>
      <p className="block ml-2 font-semibold text-lg text-black">Profiel</p>
      <div className="flex flex-col w-full items-center m-5">
        <img
          className="h-40 w-40 rounded-full object-cover"
          src={user.photoURL ? user.photoURL : profilePlaceholder}
          alt="profile"
        />
      </div>
      <p className="block ml-2 mt-8 font-body font-thin text-xs text-black">
        NAAM
      </p>
      <hr />
      <p className="block ml-2 my-2 font-body font-regular text-lg text-purple">
        {currentUsername}
      </p>
      <hr />
      <p className="flexblock ml-2 mt-8 font-body font-thin text-xs text-black">
        OVER JOU
      </p>
      <hr />
      <div className="flex-row mb-12">
        <textarea
          id="description"
          onClick={showSave}
          defaultValue={currentDescription}
          onChange={(event) => {
            currentDescription = event.target.value;
          }}
          className="w-full block h-20 ml-2 my-2 mb-2 font-body font-regular text-sm text-purple resize-none"
        />
        {isSaveButtonVisible ? (
          <button
            onClick={saveDescription}
            className="border-2 px-2 border-green rounded-md mb-2 text-base float-right"
          >
            Opslaan
          </button>
        ) : null}
      </div>
      <hr />
      <button onClick={() => logOut()} type="button">
        <p className="block ml-2 my-2 mb-2 font-body font-bold center text-sm text-purple">
          Uitloggen
        </p>
      </button>
    </div>
  );
}

function LeftSideBar({ user, callback, activeChatId, setActiveChatId }) {
  const [profileVisible, setProfileVisible] = useState(false);

  return (
    <div>
      {profileVisible ? (
        <CoachProfile user={user} callback={() => setProfileVisible(false)} />
      ) : (
        <Conversations
          user={user}
          callback={() => {
            setProfileVisible(true);
          }}
          conversationSelectedCallback={callback}
          activeChatId={activeChatId}
          setActiveChatId={setActiveChatId}
        />
      )}
    </div>
  );
}

function EmptyView() {
  const [user] = useAuthState(auth);
  const query = firestore.collection("chat").where("coach", "==", user.uid);
  const [conversations] = useCollectionData(query);

  const unreadConversations = () => {
    if (!conversations) {
      return [];
    }
    return conversations.filter(
      (item) => item.last_user_message_timestamp > item.last_read_by_coach
    );
  };

  function retrieveGreeting() {
    const today = new Date();
    const hour = today.getHours();
    if (hour < 12) {
      return "Goedemorgen";
    }
    if (hour < 18) {
      return "Goedemiddag";
    }
    return "Goedenavond";
  }

  return (
    <div className="flex flex-col col-span-2 content-center place-content-center justify-center gap-5 spacing-2 w-full bg-grey h-full">
      <p className="font-display font-bold font-lg text-purple text-center">
        {retrieveGreeting()}, {user.displayName}!
      </p>
      <p className="font-display font-regular font-lg text-purple text-center">
        Je hebt momenteel {unreadConversations().length} onbeantwoorde berichten
      </p>
      <img
        className="self-center"
        style={{ width: 635, height: 255 }}
        src={`${process.env.PUBLIC_URL}/home.svg`}
        alt="logo"
      />
    </div>
  );
}

function Messages({
  conversation,
  userInfoExpanded,
  setUserInfoExpanded,
  user,
  setActiveChatId,
}) {
  const dummy = useRef();
  const messagesRef = firestore.collection(
    `chat/${conversation.user_id}/messages`
  );

  const chatCollection = firestore.collection("chat").doc(conversation.user_id);
  const query = messagesRef.orderBy("createdAt");

  const [messages] = useCollectionData(query, { idField: "id" });
  const [formValue, setFormValue] = useState("");
  const [iconToggle, setToggled] = useState(true);

  const colSpan = userInfoExpanded ? "col-span-1" : "col-span-2";
  const colSpanReversed = userInfoExpanded ? "col-span-2" : "col-span-1";

  const sendMessage = async (e) => {
    e.preventDefault();

    await chatCollection.update({
      last_message_timestamp: Date.now(),
    });

    await messagesRef.add({
      text: formValue,
      createdAt: new Date().getTime(),
      user: {
        _id: auth.currentUser.uid,
        displayName: auth.currentUser.displayName,
      },
    });

    setFormValue("");
    dummy.current.scrollIntoView({ behavior: "smooth" });
  };

  const expandTextarea = (e) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${Math.min(e.target.scrollHeight, 160)}px`;
  };

  useEffect(() => {
    dummy.current.scrollIntoView({ behavior: "smooth" });
    document.getElementById("messages").scrollTop =
      document.getElementById("messages").scrollHeight;
  }, [messages]);
  return (
    /* eslint-disable */
    <>
      <div
        className={`${colSpan} bg-white border-r border-gray-300 max-h-screen flex flex-col`}
      >
        <div className="flex justify-between items-center pl-3 py-3">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              setToggled(false);
              setUserInfoExpanded(!userInfoExpanded);
            }}
          >
            <img
              className="h-10 w-10 rounded-full object-cover"
              src={
                conversation.user_imageUri
                  ? conversation.user_imageUri
                  : profilePlaceholder
              }
              alt="profielafbeelding"
            />
            <span className="block ml-2 font-display font-bold text-base text-black">
              {conversation.user_name}
            </span>
          </div>
          <button
            type="button"
            onClick={() => {
              setUserInfoExpanded(!userInfoExpanded);
              setActiveChatId(conversation.user_id);
            }}
          >
            {!userInfoExpanded && (
              <button
                type="button"
                onClick={() => {
                  setUserInfoExpanded(!userInfoExpanded);
                  setActiveChatId(conversation.user_id);
                }}
              >
                <BsThreeDots className="mx-5" />
              </button>
            )}
          </button>
        </div>
        <div className="w-full bg-grey flex flex-grow flex-nowrap flex-col justify-between overflow-y-auto">
          <div id="messages" className="w-full overflow-y-auto p-10 relative">
            <WelcomeMessage conversation={conversation} user={user} />
            {messages &&
              messages.map((msg) => (
                <Message
                  key={msg.id}
                  message={msg}
                  chatId={conversation.user_id}
                />
              ))}
            <span ref={dummy} />
          </div>
          <form
            className="w-full py-3 px-3 flex flex-row items-stretch justify-between border-t border-gray-300 bg-blue"
            onSubmit={sendMessage}
          >
            <textarea
              className="py-2 px-7 mx-3 h-20 pl-5 block w-full resize-none overflow-auto rounded-2xl full bg-white outline-none focus:text-gray-700"
              value={formValue}
              onChange={(e) => setFormValue(e.target.value)}
              onKeyDown={(e) => expandTextarea(e)}
              placeholder="Type hier je bericht..."
            />
            <button
              className="outline-none focus:outline-none"
              type="submit"
              disabled={!formValue}
            >
              <svg
                className="text-white h-7 w-7 origin-center transform rotate-90"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
              </svg>
            </button>
          </form>
        </div>
      </div>
      {userInfoExpanded && (
        <div className={colSpanReversed}>
          <UserInfo
            conversation={conversation}
            iconToggle={iconToggle}
            setToggled={setToggled}
            setUserInfoExpanded={setUserInfoExpanded}
            userInfoExpanded={userInfoExpanded}
          />
        </div>
      )}
    </>
  );
}

function Message({ message, chatId }) {
  const { text, user, createdAt, system } = message;
  const messageClass = system
    ? "system"
    : user._id === auth.currentUser.uid
      ? "sent"
      : "received";
  const justify =
    messageClass === "received"
      ? "justify-start"
      : messageClass === "system"
        ? "justify-center"
        : "justify-end";
  const bgColor =
    messageClass === "received"
      ? "bg-white"
      : messageClass === "system"
        ? "bg-grey-system"
        : "bg-turquoise";
  const fontColor =
    messageClass === "system" ? "text-gray-600" : "text-gray-700";
  const fontSize = messageClass === "system" ? "text-sm" : "text-base";
  const arrowPlace =
    messageClass === "received"
      ? "absolute bottom-5 -left-4 z-0 h-4 w-5 bg-white -rotate-45 transform origin-top-right"
      : messageClass === "system"
        ? null
        : "absolute bottom-5 right-0 z-0 h-4 w-5 bg-turquoise -rotate-45 transform origin-top-right";

  const [showDelete, setShowDelete] = useState(false);
  const ref = useRef();

  const deleteMessageById = (e) => {
    firestore.collection(`chat/${chatId}/messages`).doc(message.id).delete();
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowDelete(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [showDelete]);

  return (
    <div className={`w-full flex relative z-10 ${justify}`}>
      <div className={`${bgColor} rounded px-3 py-2 my-2 color relative`}>
        <div className="flex justify-between">
          <span className={`${fontColor} ${fontSize} block font-body`}>
            {text}
          </span>
          {messageClass === "sent" && (
            <div ref={ref}>
              <button
                className="relative"
                onClick={() => setShowDelete(!showDelete)}
              >
                <BsThreeDots className="ml-5 text-green-dark" />
              </button>
              {showDelete && (
                <DeleteMessage deleteMessage={deleteMessageById} />
              )}
            </div>
          )}
        </div>
        {messageClass === "system" ? null : (
          <span className="mt-1 block font-body text-xs text-right text-green-dark">
            {formatMessageDateTime(createdAt)}
          </span>
        )}
      </div>
      <p className={`${arrowPlace}`} />
    </div>
  );
}

function Main({ user }) {
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [userInfoExpanded, setUserInfoExpanded] = useState(false);
  const [activeChatId, setActiveChatId] = useState();

  return (
    <div className="h-screen w-screen">
      <div className="grid grid-cols-3 min-w-full">
        {!userInfoExpanded && (
          <LeftSideBar
            user={user}
            callback={(conversation) => setSelectedConversation(conversation)}
            activeChatId={activeChatId}
            setActiveChatId={setActiveChatId}
          />
        )}
        {selectedConversation !== null ? (
          <Messages
            conversation={selectedConversation}
            userInfoExpanded={userInfoExpanded}
            setUserInfoExpanded={setUserInfoExpanded}
            user={user}
            setActiveChatId={setActiveChatId}
          />
        ) : (
          <EmptyView user={user} />
        )}
      </div>
    </div>
  );
}

function App() {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return <div>Loading... </div>;
  }
  if (user) {
    return <Main user={user} />;
  }
  if (error) {
    return <div>There was an authentication error.</div>;
  }
  return <SignIn />;
}

export default App;
