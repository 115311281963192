import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import {
  formatDateTime,
  getLastModulesActivity,
  getLastExerciseActivity,
  getMostRecentTimestamp,
} from "./functions/datetime";

import capitalizeFirstLetter from "./functions/helpers";

// placeholder image
import profilePlaceholder from "./assets/profile-placeholder.svg";

const getBat12Score = (exercise) => {
  let score = 0;
  const answers = [];
  exercise.pages.forEach((page) => {
    page.components.forEach((component) => {
      if (component.answer) answers.push(component.answer);
    });
  });

  // calculate score
  answers.forEach((answer) => { score += answer });

  return Math.round((score / 12) * 20);
};

function Exercise({ exercise }) {
  const date = new Date(exercise.timestamp);

  const textValues = ["Nooit", "Zelden", "Soms", "Vaak", "Altijd"];

  const renderItems = () => {
    // Old exercises have different data structure
    if (exercise.components) {
      return exercise.components;
    }
    // This is for new exercises
    if (exercise.pages) {
      return exercise.pages.flatMap((x) => x.components);
    }
    return false;
  };

  const createComponent = (component) => {
    switch (component.type) {
      case "text":
        return (
          <p className="text-purple font-display font-medium">
            {capitalizeFirstLetter(component.text)}
          </p>
        );
      case "form":
        return (
          <div className="px-6 py-3 m-2 mx-auto bg-grey rounded-xl flex items-center space-x-4">
            <p>{component.answer && capitalizeFirstLetter(component.answer)}</p>
          </div>
        );
      case "slider":
        return (
          <div className="px-6 py-3 m-2 mx-auto bg-grey rounded-xl flex items-center space-x-4">
            <p>
              {component.answer}/5 - {textValues[component.answer - 1]}
            </p>
          </div>
        );
      case "totalScore":
        return (
          <div className="px-6 py-3 m-2 mx-auto bg-grey rounded-xl flex items-center space-x-4">
            <p>{capitalizeFirstLetter(component.answer)}</p>
          </div>
        );
      case "stress_update":
        return (
          <div className="px-6 py-3 m-2 mx-auto bg-grey rounded-xl space-x-4">
            <p>
              {component.answer}/5 - {textValues[component.answer - 1]}
            </p>
            <br />
            {component.emotions &&
              component.emotions.map((x, index) => {
                if (index === component.emotions.length - 1) return x;
                return `${x}, `;
              })}
          </div>
        );
      default:
        return <></>;
    }
  };
  return (
    <div className="pb-2 my-5">
      <p className="mb-2 font-medium font-display text-purple text-lg my-2">
        Oefening:&nbsp;
        {exercise.name}
      </p>
      {exercise.totalScore && (
        <p className="mb-2 font-medium font-display text-lg my-2">
          Totale Score:&nbsp;
          {exercise.totalScore}
        </p>
      )}
      <p className="mb-2 font-medium font-body text-green italic text-sm my-5">
        Gemaakt op {date.toLocaleDateString("nl-NL")} om{" "}
        {date.toLocaleTimeString("nl-NL")}
      </p>
      {exercise.name === "Burn-out zelftest" ?
        <p className="mb-2 font-large font-display text-lg my-2">
          Bat 12-score:&nbsp;
          {getBat12Score(exercise)}
        </p>
        : null}
      {renderItems() &&
        renderItems().map((component) => createComponent(component))}
    </div>
  );
}

function ExerciseResults({ conversation, activity, setActivity }) {
  const [exercises, setExercises] = useState([]);
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    if (exercises && exercises.length >= 1) {
      const lastActivity = getLastExerciseActivity(exercises);
      setActivity({ ...activity, exercises: lastActivity });
    }
  }, [exercises]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .doc(conversation.user_id)
      .collection("exercises")
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push(doc.data());
        });
        setExercises(arr);
      });
  }, [conversation.user_id]);

  return (
    <div className="bg-white p-4 pl-6 flex-row rounded-lg col-span-2 pt-4">
      <button onClick={() => setExpanded((prev) => !prev)}>
        <div className="flex flex-row items-center">
          <h2 className="mb-2 font-display font-bold text-black text-xl my-2">
            Oefeningen
          </h2>
          {isExpanded ? (
            <BsChevronUp className="mx-10" />
          ) : (
            <BsChevronDown className="mx-10" />
          )}
          <p className="text-xs font-body text-green">
            {activity.exercises && formatDateTime(activity.exercises)}
          </p>
        </div>
      </button>
      {isExpanded &&
        exercises &&
        exercises
          .filter(
            (exercise) =>
              exercise.shared_with_coach ||
              typeof exercise.shared_with_coach === "undefined"
          )
          .sort((x, y) => y.timestamp - x.timestamp)
          .map((exercise) => <Exercise exercise={exercise} />)}
    </div>
  );
}

function ModuleResults({ conversation, activity, setActivity }) {
  const [modules, setModules] = useState([]);
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    if (modules.length === 0) return;
    const lastActivity = getLastModulesActivity(modules);
    setActivity({ ...activity, modules: lastActivity });
  }, [modules]);

  useEffect(() => {
    const result = [];
    const promises = [];

    firebase
      .firestore()
      .collection("users")
      .doc(conversation.user_id)
      .collection("modules")
      .orderBy("order", "asc")
      .get()
      .then((modules) => {
        modules.forEach(module => {
          const p = firebase
            .firestore()
            .collection("users")
            .doc(conversation.user_id)
            .collection("modules")
            .doc(module.id)
            .collection("steps")
            .get()
            .then((steps) => {
              const arr = [];
              steps.forEach((step) => arr.push(step.data()));
              result.push({
                id: module.data().id,
                name: module.data().name,
                steps: arr,
              });
            });
          promises.push(p);
        });
      });

    Promise.all(promises).then(() => {
      setModules(result);
    });
  }, [conversation.user_id]);

  return (
    <div className="bg-white p-4 pl-6 flex-row rounded-lg col-span-2 pt-4">
      <button onClick={() => setExpanded((prev) => !prev)}>
        <div className="flex flex-row items-center">
          <h2 className="mb-2 font-display font-bold text-black text-xl my-2">
            Modules
          </h2>
          {isExpanded ? (
            <BsChevronUp className="mx-10" />
          ) : (
            <BsChevronDown className="mx-10" />
          )}
          <p className="text-xs font-body text-green">
            {activity.modules && formatDateTime(activity.modules)}
          </p>
        </div>
      </button>
      {isExpanded &&
        modules &&
        modules.map((module) => <Module module={module} />)}
    </div>
  );
}

function Module({ module }) {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <div className="bg-white p-4 pl-1 flex-row col-span-2 pt-4 border-b-2">
      <button onClick={() => setExpanded((prev) => !prev)}>
        <div className="flex flex-row items-center ">
          <h2 className="mb-2 font-display font-medium text-purple text-lg my-2">
            {module.name}
          </h2>
          <img
            className={`h-5 w-5 m-3 ${isExpanded && "transform -rotate-90"}`}
            src={`${process.env.PUBLIC_URL}/dropdown-arrow.svg`}
            alt="logo"
          />
        </div>
      </button>
      {isExpanded &&
        module &&
        module.steps
          .filter(
            (step) =>
              step.shared_with_coach ||
              typeof step.shared_with_coach === "undefined"
          )
          .map((step) => <Step step={step} />)}
    </div>
  );
}

function Step({ step }) {
  return (
    <div className="pt-4">
      <p className="font-display font-semibold text-lg text-purple mb-1">
        {step.name}
        <span className="ml-2 text-gray-600 font-light text-xs">
          {step.timestamp && formatDateTime(step.timestamp)}
        </span>
      </p>
      {step.pages && step.pages.map((page) => <Page page={page} />)}
    </div>
  );
}

function Page({ page }) {
  return (
    <div className="my-4">
      {page.components &&
        page.components.map((component) => (
          <div>
            <PageAnswers answers={component} />
          </div>
        ))}
    </div>
  );
}

function PageAnswers({ answers }) {
  switch (answers.type) {
    case "selection":
      return (
        <div className="grid grid-cols-3 gap-2 m-1">
          {answers.answer &&
            answers.answer.map((x) => (
              <div className="bg-blue-500 w-auto rounded-2xl items-center flex justify-center">
                <p className="font-bold text-sm text-white place-self-center text-center p-2">
                  {answers.options[x]}
                </p>
              </div>
            ))}
        </div>
      );
    case "form":
      return (
        <div>
          <div className="px-6 py-3 m-3 mx-auto bg-grey rounded-xl flex items-center space-x-4">
            <p>{answers.answer && capitalizeFirstLetter(answers.answer)}</p>
          </div>
        </div>
      );
    case "slider":
      return (
        <div className="px-6 py-3 m-3 mx-auto bg-grey rounded-xl flex items-center space-x-4">
          <p>{answers.answer && answers.answer}</p>
        </div>
      );
    case "question":
      return (
        <div className="font-display font-400 text-base text-purple my-2">
          <p>{answers.text && capitalizeFirstLetter(answers.text)}</p>
        </div>
      );
    case "text":
      return (
        <div className="font-display font-400 text-base text-purple my-2">
          <p>{answers.text && capitalizeFirstLetter(answers.text)}</p>
        </div>
      );
    default:
      return <></>;
  }
}

function Info({ conversation, activity }) {
  let mostRecentActivity = getMostRecentTimestamp(
    activity.exercises,
    activity.modules
  );
  const underLine = "border-b-2 border-gray-200 mt-2";

  return (
    <div className="bg-white p-4 flex flex-col justify-between rounded-lg">
      <div className="flex flex-row items-center">
        <img
          className="h-10 w-10 rounded-full object-cover mr-2"
          src={conversation.user_imageUri || profilePlaceholder}
          alt="profielafbeelding"
        />
        <h2 className="mb-2 font-display font-bold text-black text-xl my-2">
          {conversation.user_name}
        </h2>
      </div>
      <div className="grid grid-cols-3 gap-x-4 font-light font-display text-justify align-text-bottom">
        <p className={`col-span-1 text-sm ${underLine}`}>BEDRIJF/TEAM</p>
        <p className={`col-span-2 text-base font-semibold ${underLine}`}>
          {conversation.user_organisation}
        </p>
        <p className={`col-span-1 text-sm ${underLine}`}>LAATSTE ACTIVITEIT</p>
        <p className={`col-span-2 text-base font-semibold ${underLine}`}>
          {mostRecentActivity && formatDateTime(mostRecentActivity)}
        </p>
      </div>
    </div>
  );
}

function Progress({ conversation }) {
  const [result, setResult] = useState({});

  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .doc(conversation.user_id)
      .collection("modules")
      .get()
      .then((modulesSnapshot) => {
        modulesSnapshot.forEach((module) => {
          const { stepsData } = module.data();
          firebase
            .firestore()
            .collection("users")
            .doc(conversation.user_id)
            .collection("modules")
            .doc(module.id)
            .collection("steps")
            .get()
            .then((stepsSnapshot) => {
              let i = 1;
              stepsSnapshot.forEach((step, index) => {
                if (step.data().isDone) {
                  setResult(prev =>
                  ({
                    ...prev, [module.data().name]:
                      { ...prev[module.data().name], [`${i}. ${stepsData[step.id].name}`]: step.data().isDone }
                  }));
                }
                i++;
              });
            });
        });
      });
  }, [conversation.user_id]);

  return (
    <div className="bg-white p-4 rounded-lg">
      <h2 className="mb-2 font-display font-bold text-black text-xl my-2">
        Voortgang
      </h2>
      {Object.entries(result).map(([key, value]) => (
        <div className="flex flex-col items-start">
          <p className="text-md font-display font-bold text-black my-2">{key}</p>
          {Object.keys(value).map((k1) => (
            <div className="flex flex-col items-center">
              <p className="text-sm font-display font-bold text-black my-2">✅ {k1}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

function UserInfo({ conversation, setUserInfoExpanded, userInfoExpanded }) {
  const [activity, setActivity] = useState({
    modules: "",
    exercises: "",
  });

  return (
    <div className="h-screen bg-grey overflow-y-auto">
      <div className="flex justify-between items-center pl-5 py-3 h-16 bg-white">
        <div className="flex items-center">
          <button onClick={() => setUserInfoExpanded(!userInfoExpanded)}>
            <img
              className="h-9 w-9"
              src={`${process.env.PUBLIC_URL}/close_info_menu.svg`}
              alt="logo"
            />
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </button>
          <span className="block ml-4 font-display font-bold text-base text-black">
            Over {conversation.user_name}
          </span>
        </div>
      </div>
      <div className="w-full py-3 px-6 bg-grey">
        <div className="max-h-full overflow-y-auto">
          <div className="grid grid-cols-2 min-w-full gap-4 max-h-full overflow-y-auto">
            <Info conversation={conversation} activity={activity} />
            <Progress conversation={conversation} />
            <ModuleResults
              conversation={conversation}
              activity={activity}
              setActivity={setActivity}
            />
            <ExerciseResults
              conversation={conversation}
              activity={activity}
              setActivity={setActivity}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserInfo;
