import React, { useState, useEffect } from "react";
import firebase from "firebase";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const NoAdminMessage = () => (
    <>
      <p>Je kunt niet inloggen omdat je niet staat geregistreerd als coach of admin. </p>
      <p>
        Neem contact op met&nbsp;
        <a className="underline hover:text-gray-500" href="mailto:corne@fivesteps.nl">corne@fivesteps.nl</a>
      </p>
    </>
  );

  const previousError = localStorage.getItem("loginError");

  useEffect(() => {
    if (previousError === "No admin/coach") {
      setError({ code: "No admin/coach" });
    }
  }, []);

  const checkIfAdminOrCoach = () => {
    // if user isn't admin or coach, sign out
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        if (!!idTokenResult.claims.admin || !!idTokenResult.claims.coach) {
          localStorage.removeItem("loginError");
        } else {
          localStorage.setItem("loginError", "No admin/coach");
          firebase.auth().signOut();
        }
      });
  };

  const logIn = () => {
    setLoading(true);

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        checkIfAdminOrCoach();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      logIn();
    }
  };

  return (
    <div className="w-500 h-300 py-3 px-3 flex flex-col items-center m-auto">
      <div className="py-3 px-3 flex flex-col items-center space-y-5">
        <p className="font-display">
          Welkom bij de FiveSteps admin. Log hieronder in met je e-mail adres en
          wachtwoord.
        </p>
        { error
        && (
          <div className="bg-red-200 py-2 px-4 rounded-full w-full text-gray-800 text-center transition-opacity">
            <p>{error.code && `Code: ${error.code}`}</p>
            <p>
              {error.message && error.message}
              {previousError === "No admin/coach" && <NoAdminMessage />}
            </p>
          </div>
        )}
        <input
          className="py-2 mx-3 pl-5 block w-full rounded-full bg-gray-100 outline-none focus:text-gray-700"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="E-mail adres"
          disabled={loading}
        />
        <input
          className="py-2 mx-3 pl-5 block w-full rounded-full bg-gray-100 outline-none focus:text-gray-700"
          value={password}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={loading}
          placeholder="Wachtwoord"
        />
        <button
          className="outline-none focus:outline-none"
          type="submit"
          onClick={logIn}
          disabled={loading}
        >
          Log in
        </button>
      </div>
    </div>
  );
}

export default SignIn;
