import React from "react";
import trash from "../assets/trash.svg";

function DeleteMessage({ deleteMessage }) {
  return (
    <button
      className="absolute flex top-9 right-0 bg-white pl-4 pr-8 py-2 shadow-xl text-xs font-body rounded-lg items-center text-black justify-center z-1"
      onClick={() => deleteMessage()}
    >
      <div className="absolute bg-white h-5 w-5 transform rotate-45 -top-1 right-2" />
      <img src={trash} alt="delete" className="mr-2 text-black" />
      <p>Verwijderen</p>
    </button>
  );
}

export default DeleteMessage;
