import React from "react";

import welcome from "../assets/welcome.svg";

function WelcomeMessage({ conversation, user }) {
  return (
    <div className="flex flex-col justify-center w-full place-items-center mb-4">
      <img src={welcome} alt="welkom" className="object-cover p-4 max-w-1/3" />
      <span className="bg-purple py-5 px-10 rounded-full text-white relative w-96 font-medium text-center">
        Hi
        {" "}
        {conversation.user_name}
        , onze coach
        {" "}
        {user.displayName }
        {" "}
        gaat jouw vragen beantwoorden. Je krijgt altijd z.s.m. antwoord.
      </span>
    </div>
  );
}

export default WelcomeMessage;
